import React from "react";
import "./Hero.scss"
import HomeComputerImage from "../../assets/image/principal/png/Home-Computador.png";
import DashBoardImage from "../../assets/image/principal/png/NuvemFiscalHome2.png";
import ZoomImage from "../../components/ZoomImage";


const Hero = ({className,...rest}) => {
    return (
      <div className={`hero-section ${className}`} {...rest}>
        <div className="containers-section" >
          <div
            className="img-section"
            data-aos="fade-right"
            data-aos-delay="500"
          >
            <div className="texto-section">
              <h1 className="titulo">
                <span className="titulo-span">Bem-vindo à Nuvem Fiscal</span>
              </h1>
              <br/>
               <p className="texto-section">
               Obrigado pela sua visita! Esperamos que tenha aproveitado o evento!
               </p>
               <p className="texto-section">
               A Nuvem Fiscal é a plataforma de automação comercial e fiscal para desenvolvedores, 
               incluindo uma API REST que oferece endpoints para emissão de <b>documentos fiscais como NFe, NFSe, NFCe, NFCom, MDFe e CTe</b>.
               </p>
               <p className="texto-section">
               Como agradecimento pela sua participação on evento, lhe convidamos a participar no 
               <strong><a href="https://discord.gg/9TnJUqG6WM">grupo VIP do Discord na Nuvem Fiscal</a></strong>.
               Venha trocar experiências e tirar suas dúvidas!
               </p>
              <div className="btn-group d-inline-block mt-10">
              <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(61, 59, 148)", color: "white"}} href={"https://console.nuvemfiscal.com.br"}>
                 Comece Agora
              </a>
              </div>
              <div className="btn-group d-inline-block mt-10 ml-lg-5">
                <a className="btn btn-2 rounded-5" style={{backgroundColor: "rgb(250, 128, 114)", color: "white"}} href={"https://discord.gg/9TnJUqG6WM"}>
                  Participar do grupo VIP
                </a>
              </div>

            </div>
            <img src={HomeComputerImage} alt=""/>
          </div>
           <div className="dashboard-image">
            <ZoomImage className='img-container' src={DashBoardImage} alt="" />
           </div>
        </div>
    </div>
  );
}


export default Hero;